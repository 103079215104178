import { Mapper } from '@amarkets/atlas/esm/lib/mapper';

export class DailyEventsMapper extends Mapper {
  _eventsFactory;

  constructor(eventsFactory) {
    super();

    this._eventsFactory = eventsFactory;
  }

  mapEvents = (response) => response.data.reverse().map(
    ({ id, type, attributes }) => DailyEventsMapper._getResult({ id, type, ...attributes }, this._eventsFactory),
  );
}
