import { Action } from '@amarkets/atlas/esm/lib/action';
import { GeneralItem } from '../entities/general-item';
import { DailyEventsMapper } from '../mappers/daily-events-mapper';
import { MUTATION_SET_DAILY_EVENTS } from '../types';

export class FetchDailyEvents extends Action {
  _api;
  _errorHandler;

  constructor({ premiumAnalyticsApi, errorHandler }) {
    super();
    this._api = premiumAnalyticsApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    try {
      const eventsMapper = new DailyEventsMapper((opts) => new GeneralItem(opts));
      const events = await this._api.getDailyEvents((data) => eventsMapper.mapEvents(data));

      commit(MUTATION_SET_DAILY_EVENTS, events);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchDailyEvents');
    }
  }
}
