import { CURRENT_GOLD_MAX_EQUITY, LOYALTY_STATUSES, DAYS_COUNT_FOR_GOLD_PERIOD } from '@/constants';

export class UserProfile {
  constructor({
    id,
    countryName,
    createdAt,
    email,
    fullName,
    loyaltyStatus,
    goldVolume,
    goldEquity,
    goldDateOfAssignment,
    goldShow,
    goldMaxVolume,
    personalManager,
    officeLocale,
    officeSignedUpAt,
    officeHasPayments,
    officeFirstPaymentAt,
    officeHasTrades,
    officeCountTrade,
    officeUserCounter,
    officeHasVerified,
    officeHasWithdrawals,
    officeWithdrawalsCount,
    officeBonusApplicable,
    officeTotalLocked,
    settings: {
      showBalanceInTitle,
      showPromoBanner,
      latestTimeSuccessVerification,
      showGoldUpdatesBanner,
    },
    additionalVerification: {
      required,
      url,
    },
  }) {
    Object.assign(this, {
      id,
      goldMaxEquity: CURRENT_GOLD_MAX_EQUITY,
      isGoldShown: goldShow,
      isGoldStatus: loyaltyStatus === LOYALTY_STATUSES.GOLD && goldShow,
      goldStatusDaysLeft: UserProfile.getGoldStatusDaysLeft(goldDateOfAssignment),
      personalManager: UserProfile.getPersonalManager(personalManager),
      countryName,
      createdAt,
      email,
      fullName,
      loyaltyStatus,
      goldVolume,
      goldEquity,
      goldDateOfAssignment,
      goldMaxVolume,
      officeLocale,
      officeSignedUpAt,
      officeHasPayments,
      officeFirstPaymentAt,
      officeHasTrades,
      officeCountTrade,
      officeUserCounter,
      officeHasVerified,
      officeHasWithdrawals,
      officeWithdrawalsCount,
      officeBonusApplicable,
      officeTotalLocked,
      locale: officeLocale,
      isBalanceInTitleShown: showBalanceInTitle,
      isPromoBannerShown: showPromoBanner,
      isGoldUpdateBannerShown: showGoldUpdatesBanner,
      isAdditionalVerificationRequired: required,
      additionalVerificationUrl: url,
      latestDisplayTimeSuccessVerificationModal: latestTimeSuccessVerification,
    });
  }

  static getGoldStatusDaysLeft(goldDateOfAssignment) {
    if (!goldDateOfAssignment) return null;

    const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
    const goldAssignmentDate = new Date(goldDateOfAssignment);
    const factualDifference = Math.floor((new Date() - goldAssignmentDate) / MILLISECONDS_IN_DAY);
    const displayedDifference = DAYS_COUNT_FOR_GOLD_PERIOD - factualDifference;

    return displayedDifference >= 0 ? displayedDifference : 0;
  }

  static getPersonalManager(personalManager) {
    if (!personalManager) return null;

    const isPersonalManager = Object.values(personalManager).some((value) => value !== null);
    return isPersonalManager ? personalManager : null;
  }
}
