import { useI18n } from 'vue-i18n';
import { languageStorage } from '@amarkets/core-vue';

import { useDisplayProvide } from './use-display-provide';

export function useLanguage() {
  const { locale, t } = useI18n();
  const { isRtl } = useDisplayProvide();

  const changeLocale = async (_locale) => {
    // locale.value = _locale; // TODO: temporary decision

    languageStorage.saveLocale(_locale);
  };

  return {
    currentLocale: locale.value,
    changeLocale,
    isRtl,
    $t: t,
  };
}
