import { Action } from '@amarkets/atlas/esm/lib/action';
import { EventItem } from '../entities/event-item';
import { SingleItemMapper } from '../mappers/single-item-mapper';
import { MUTATION_SET_EVENT_ITEM } from '../types';

export class FetchEventItem extends Action {
  _api;
  _errorHandler;

  constructor({ premiumAnalyticsApi, errorHandler }) {
    super();
    this._api = premiumAnalyticsApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { eventId, locale }) {
    try {
      const itemMapper = new SingleItemMapper((opts) => new EventItem({ ...opts, locale }));
      const eventItem = await this._api.getEventItem({ eventId }, (data) => itemMapper.mapItem(data));

      commit(MUTATION_SET_EVENT_ITEM, eventItem);
    } catch (e) {
      this._errorHandler.handle(e, 'while execute FetchEventItem');
    }
  }
}
