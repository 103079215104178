import { Action } from '@amarkets/atlas/esm/lib/action';
import { reloadPage } from '@/lib/utils';

export class UpdateLocale extends Action {
  _api;
  _errorHandler;
  _globalLoader;

  constructor({
    officeApi,
    errorHandler,
    globalLoader,
  }) {
    super();
    this._api = officeApi;
    this._errorHandler = errorHandler;
    this._globalLoader = globalLoader;
  }

  async execute(_, { locale }) {
    try {
      this._globalLoader.setToLoading();
      await this._api.updateLocale(locale);

      await reloadPage();
    } catch (e) {
      this._errorHandler.handle(e, 'while execute UpdateLocale');
    }
  }
}
