import { BreakpointAdapter } from '@amarkets/atlas/esm/lib/breakpoint-adapter';
import { LanguageAdapter } from '@amarkets/atlas/esm/lib/language-adapter';
import {
  FacadeGlobal,
  FacadeAuth,
  FacadeIdentity,
  FacadePaymentMethods,
} from '@/plugins/store/facades';
import { LOCALES } from '@/constants';

export function createUiServiceProvider({
  uiNotifier,
  store,
  vuetify,
  i18n,
  app,
}) {
  const facadeAuth = new FacadeAuth(store);
  const service = {
    language: new LanguageAdapter({
      vuetify,
      getCurrentLocale: () => i18n.locale,
      locales: LOCALES,
    }),
    isRtlLayout: () => vuetify.rtl,
    uiNotifier,
    facadeGlobal: new FacadeGlobal(store),
    facadeAuth,
    facadeIdentity: new FacadeIdentity(store),
    facadePaymentMethods: new FacadePaymentMethods(store),
    breakpoint: new BreakpointAdapter({ breakpoint: vuetify.display.name }),
  };

  Object.entries(service).forEach(([key, value]) => {
    app.provide(key, value);
  });
}
