import { ROUTES, PROFILE_ROUTES, LAYOUT_NAMES } from '@/constants';
import { availabilityOfPrimaryStateParam } from '../guards';

export const profileRoutes = [
  {
    path: ROUTES.PROFILE.PATH,
    name: ROUTES.PROFILE.NAME,
    component: () => import(
      '@/views/page-profile/page-profile.vue'
    ),
    meta: { layout: LAYOUT_NAMES.MAIN },
    children: [
      {
        path: PROFILE_ROUTES.VERIFY.PATH,
        name: PROFILE_ROUTES.VERIFY.NAME,
        component: () => import(
          '@/widgets/modal-verification/modal-verification.vue'
        ),
        meta: { layout: LAYOUT_NAMES.MAIN },
      },
      {
        path: PROFILE_ROUTES.PHONE.PATH,
        name: PROFILE_ROUTES.PHONE.NAME,
        component: () => import(
          '@/modules/phone/components/module-root/module-root.vue'
        ),
        meta: { layout: LAYOUT_NAMES.MAIN },
        props: true,
        beforeEnter: (to, from, next) => availabilityOfPrimaryStateParam(to, next),
      },
      {
        path: PROFILE_ROUTES.EMAIL.PATH,
        name: PROFILE_ROUTES.EMAIL.NAME,
        component: () => import(
          '@/modules/email/components/module-root/module-root.vue'
        ),
        meta: { layout: LAYOUT_NAMES.MAIN },
        props: true,
        beforeEnter: (to, from, next) => availabilityOfPrimaryStateParam(to, next),
      },
      {
        path: PROFILE_ROUTES.IDENTITY.PATH,
        name: PROFILE_ROUTES.IDENTITY.NAME,
        component: () => import(
          '@/modules/identity/components/module-root/module-root.vue'
        ),
        meta: { layout: LAYOUT_NAMES.MAIN },
      },
      {
        path: PROFILE_ROUTES.PASSWORD.PATH,
        name: PROFILE_ROUTES.PASSWORD.NAME,
        component: () => import(
          '@/modules/password/components/module-root/module-root.vue'
        ),
        meta: { layout: LAYOUT_NAMES.MAIN },
      },
      {
        path: PROFILE_ROUTES.ADD_SECOND_FACTOR.PATH,
        name: PROFILE_ROUTES.ADD_SECOND_FACTOR.NAME,
        component: () => import(
          '@/modules/enable-second-factor/components/module-root/module-root.vue'
        ),
        meta: { layout: LAYOUT_NAMES.MAIN },
      },
      {
        path: PROFILE_ROUTES.DISABLED_SECOND_FACTOR.PATH,
        name: PROFILE_ROUTES.DISABLED_SECOND_FACTOR.NAME,
        component: () => import(
          '@/modules/disable-second-factor/components/module-root/module-root.vue'
        ),
        meta: { layout: LAYOUT_NAMES.MAIN },
      },
    ],
  },
  {
    path: PROFILE_ROUTES.SESSIONS.PATH,
    name: PROFILE_ROUTES.SESSIONS.NAME,
    component: () => import(
      '@/views/page-profile-sessions/page-profile-sessions.vue'
    ),
    meta: { layout: LAYOUT_NAMES.MAIN },
  },
];
