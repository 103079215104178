import packageInfo from '../../../package.json';
import LOCALES from '../../constants/locales';

export const createConfig = ({ authSSODomain } = {}) => ({
  APP: {
    APP_RELEASE: `${packageInfo.name}@${packageInfo.version}`,
    DEFAULT_LOCALE: LOCALES.EN,
    BASE_URL: import.meta.env.BASE_URL,
    ENV: import.meta.env.NODE_ENV,
    ROUTER_BASE: import.meta.env.VITE_ROUTER_BASE,
  },
  SENTRY: {
    ENV: import.meta.env.VITE_SENTRY_ENV,
    DSN: import.meta.env.VITE_SENTRY_DSN,
  },
  API: {
    EXTERNAL: import.meta.env.VITE_API_URL_EXTERNAL,
    AUTH: authSSODomain?.API
      ?? import.meta.env.VITE_API_URL_AUTH,
    AGORA: import.meta.env.VITE_API_URL_AGORA,
    PA: import.meta.env.VITE_API_URL_PREMIUM_ANALYTICS,
  },
  SSO: {
    AUTH_SERVER_URI: authSSODomain?.SSO
      ?? import.meta.env.VITE_AUTH_SERVER_URI,
    CLIENT_ID: import.meta.env.VITE_CLIENT_ID,
  },
});
