import { FINANCE_ROUTES, LAYOUT_NAMES } from '@/constants';

export const financeRoutes = [
  {
    path: FINANCE_ROUTES.PAYMENT_METHODS.PATH,
    name: FINANCE_ROUTES.PAYMENT_METHODS.NAME,
    component: () => import(
      '@/views/page-payment-method/page-payment-methods/page-payment-methods.vue'
    ),
    meta: { layout: LAYOUT_NAMES.MAIN },
    children: [
      {
        path: FINANCE_ROUTES.EDIT_MODE_PAYMENT_METHOD.PATH,
        name: FINANCE_ROUTES.EDIT_MODE_PAYMENT_METHOD.NAME,
        component: () => import(
          '@/views/page-payment-method/page-edit-payment-method/page-edit-payment-method.vue'
        ),
        props: true,
        meta: { layout: LAYOUT_NAMES.MAIN },
      },
    ],
  },
  {
    path: FINANCE_ROUTES.PAYMENT_METHOD_TYPES.PATH,
    name: FINANCE_ROUTES.PAYMENT_METHOD_TYPES.NAME,
    component: () => import(
      /* webpackChunkName: "page-payment-methods-types" */
      '@/views/page-payment-method/page-payment-methods-types/page-payment-methods-types.vue'
    ),
    meta: { layout: LAYOUT_NAMES.MAIN },
  },
  {
    path: FINANCE_ROUTES.NEW_PAYMENT_METHOD.PATH,
    name: FINANCE_ROUTES.NEW_PAYMENT_METHOD.NAME,
    component: () => import(
      /* webpackChunkName: "page-new-payment-method" */
      '@/views/page-payment-method/new-payment-method/page-new-payment-method.vue'
    ),
    props: true,
    meta: { layout: LAYOUT_NAMES.MAIN },
  },
];
