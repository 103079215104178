export default {
  PAYMENT_METHODS: {
    PATH: '/finance/payment-methods',
    NAME: 'payment-methods',
  },
  PAYMENT_METHOD_TYPES: {
    PATH: '/finance/payment-method-types',
    NAME: 'payment-method-types',
  },
  NEW_PAYMENT_METHOD: {
    PATH: '/finance/new-payment-method/:paymentType',
    NAME: 'add-payment-method',
  },
  EDIT_MODE_PAYMENT_METHOD: {
    PATH: ':paymentType/:uid/edit',
    NAME: 'edit-payment-method',
  },
};
