import { Currency } from '@amarkets/atlas/esm/lib/currency';

export class AccountProgress {
  constructor({
    equity,
    margin,
    marginFree,
    dailyPnl,
    currency,
  }) {
    Object.assign(this, {
      allMarginProgress: AccountProgress.getValueMarginProgress({
        margin,
        marginFree,
      }, currency),
      accountValue: AccountProgress.getValueWithCurrency(equity, currency),
      margin: AccountProgress.getValueWithCurrency(margin, currency),
      freeMargin: AccountProgress.getValueWithCurrency(marginFree, currency),
      dailyPnl: AccountProgress.getValueWithCurrency(dailyPnl),
    });
  }

  static getValueWithCurrency(value, symbol = 'USD') {
    return new Currency({
      precision: 2,
      value,
      symbol,
    }).currency.format();
  }

  static getValueMarginProgress({ margin, marginFree }) {
    if (margin === 0 || !margin) return 0;
    const res = (margin * 100) / (margin + marginFree);

    return res && res.toFixed(2);
  }
}
